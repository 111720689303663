import React from "react"
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'

import Layout from "../components/layout-v2"
import SEO from "../components/seo"
import ContactUs from "../components/contact-us"

import { BsArrowRight } from 'react-icons/bs'

import TestimonialBg from '../images/testimonial-bg.png'
import StartQuote from '../images/start-quote.png'
import EndQuote from '../images/end-quote.png'

const ServicesPage = ({ data: { servicePost, pageContent } }) => {

    const services = servicePost.edges
    const content = pageContent.edges

    return(
        <Layout>
            {content.map(page => ( 
                <SEO 
                title={page.node.SEOmeta.metaTitle} 
                description={page.node.SEOmeta.metaDescription}
                keywords={page.node.SEOmeta.metaKeywords}
                ogTitle={page.node.SEOmeta.ogTitle} 
                ogDescription={page.node.SEOmeta.ogDescription}
                ogImage={page.node.SEOmeta.ogImage.localFile.childImageSharp.fluid}
                twitterTitle={page.node.SEOmeta.twitterTitle} 
                twitterDescription={page.node.SEOmeta.twitterDescription}
                twitterImage={page.node.SEOmeta.twitterImage.localFile.childImageSharp.fluid}
                />
            ))}
            <div style={{padding: '0 20px', }}>
                <TitleSection>
                    <h1>Services</h1>
                    <p>White Lion Interactive is a digital agency</p>
                </TitleSection>
                <CopySection>
                    {content.map(page => ( 
                        <div dangerouslySetInnerHTML={{ __html: page.node.ServicesPageContent.firstParagraph }} />
                    ))}
                </CopySection>
                <ServiceLoop>
                    {services.map(service => (
                        <article
                        itemScope
                        itemType="http://schema.org/Article"
                        >
                            <Link to={`/services/${service.node.slug}`} itemProp="url" >
                                <div>
                                    <h3 dangerouslySetInnerHTML={{ __html: service.node.title }} itemProp="headline" />
                                    <div class="service-description" dangerouslySetInnerHTML={{ __html: service.node.ServiceDescription.serviceDescriptionContent}} itemProp="description" />
                                </div>
                                <div class="service-button">Learn More <BsArrowRight size={24}/></div>
                            </Link>
                        </article>
                    ))}
                </ServiceLoop>
                {content.map(page => ( 
                <TestimonialBlock
                itemScope
                itemType="http://schema.org/Review"
                >
                    <Background>
                        <img src={TestimonialBg} class="testimonial-bg" alt="Gray dotted background" height="650" width="1320" />
                    </Background>
                    <TestimonialMain>
                        <img src={StartQuote} class="start-quote" alt="Start Quote" height="235" width="108" />
                        <div 
                        itemProp="reviewBody"
                        dangerouslySetInnerHTML={{__html: page.node.ServicesPageContent.servicesPageTestimonial.spTestimonialContent}} 
                        />
                        <img src={EndQuote} class="end-quote" alt="End Quote" height="235" width="107" />
                    </TestimonialMain>
                    <TestimonialMeta>
                        <h4 itemProp="author">{page.node.ServicesPageContent.servicesPageTestimonial.spTestimonialAuthor}</h4>
                        <p>{page.node.ServicesPageContent.servicesPageTestimonial.spTestimonialAuthorInfo}</p>
                    </TestimonialMeta>
                </TestimonialBlock>
                ))}
                <ContactUs />
            </div>
        </Layout>
    );

}

const TitleSection = styled.header`
    max-width: 1320px;
    width: 100%;
    padding: 80px 70px;
    margin: 0 auto; 
    margin-top: 20px;
    margin-bottom: 60px;
    border: 10px solid #db4140;
    h1 {
        font-size: 100px;
        margin-top: 0;
        margin-bottom: 0;
    }
    p {
        font-family: "Work Sans";
        font-size: 30px;
        font-weight: 400;
        color: #777c7c;
        margin-bottom: 0;
    }
  @media(max-width:1000px) {
    max-width: 700px;
    padding: 45px;
    h1 {
      font-size: 60px;
    }
    p {
      font-size: 24px;
    }
  }
  @media(max-width: 767px) {
    max-width: 500px;
    padding: 20px;
    border: 5px solid #db4140;
    margin-bottom: 20px;
    h1 {
      font-size: 44px;
    }
    p {
      font-size: 18px;
      line-height: 1.4;
    }
  }
`

const CopySection = styled.section`
    max-width: 770px;
    width: 100%;
    margin: 100px auto;
    padding: 0;
    h2 {
        font-family: "Nobel",sans-serif;
        font-size: 65px;
        line-height: 1.1;
        font-weight: 700;
        color: #2b2520;
        margin-top: 0;
        margin-bottom: 10px;
    }
    p {
        font-family: "Work Sans";
        font-size: 24px;
        line-height: 34px;
        font-weight: 400;
        color: #777C7C;
        margin-top: 0;
        margin-bottom: 0;
    }
    @media(max-width:1000px) {
      max-width: 700px;
      padding: 0 55px;
      h2 {
        font-size: 40px;
      }
      p {
        font-size: 20px;
      }
    }
    @media(max-width: 767px) {
      max-width: 500px;
      margin: 20px auto;
      padding: 0px;
      h2 {
        font-size: 24px;
        line-height: 1.3;
      }
      p {
        font-size: 16px;
        line-height: 1.4;
      }
    }
`

const ServiceLoop = styled.section`
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    article {
        width: calc(33.33% - 15px);
        margin-top: 0;
        margin-bottom: 30px;
        a {
            display: block;
            padding: 40px 0px;
            width: 100%;
            height: 100%;
            border: 5px solid #eaeaea;
            background-color: transparent;
            transition: all .3s cubic-bezier(.77,0,.175,1);
            text-decoration: none !important;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            > div {
                width: 100%;
            }
        }
        h3 {
            font-family: din-condensed, sans-serif;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 1px;
            font-size: 30px;
            line-height: 40px;
            color: #db4140;
            text-transform: uppercase;
            text-align: center;
            padding: 0 30px;
            margin-top: 0;
            margin-bottom: 30px;
            width: 100%;
        }
        ul {
            font-family: "Work Sans";
            font-size: 18px;
            font-weight: 400;
            color: #2b2520;
            padding-left: 20px;
            margin: 0 40px;
            list-style-position: outside;
            transition: all .3s cubic-bezier(.77,0,.175,1);
            li {
                margin-bottom: 0;
                line-height: 2;
            }
        }
        .service-description {
            width: 100%;
        }
        .service-button {
            font-family: "Work Sans";
            font-size: 20px;
            line-height: 50px;
            font-weight: 700;
            color: #2b2520;
            text-decoration: none;
            border: 3px solid #db4140;
            display: block;
            width: 250px;
            height: 50px;
            margin: 0 auto;
            margin-top: 30px;
            align-self: flex-end;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .3s cubic-bezier(.77,0,.175,1);
            svg {
              color: #db4140;
              margin-left: 10px;
              transition: all .3s cubic-bezier(.77,0,.175,1);
            }
            &:hover {
              svg {
                transform: translateX(10px);
              }
            }
        }
        &:hover {
            a {
                background-color: #2b2520;
                border-color: #2b2520;
            }
            ul {
                color: #fff;
            }
            .service-button {
                background-color: #db4140;
                color: #fff;
                svg {
                    color: #fff;
                }
            }
        }
    }
    @media(max-width:1000px) {
      max-width: 700px;
      article {
        width: calc(50% - 15px)
      }
    }
    @media(max-width: 767px) {
      max-width: 500px;
      margin: 50px auto;
      padding: 0px;
      article {
        width: 100%;
        a {
          padding: 20px 0;
          padding-top: 40px; 
        }
        ul {
          margin: 0 20px;
        }
      }
    }
`

const TestimonialBlock = styled.section`
  position: relative;
  max-width: 1360px;
  width: 100%;
  margin: 70px auto;
  padding: 0 20px;
  padding-top: 180px;
  @media(max-width:1000px) {
    max-width: 700px;
  }
  @media(max-width: 767px) {
    max-width: 500px;
    margin: 50px auto;
    padding-top: 80px;
    overflow: hidden;
  }
`

const Background= styled.div`
  position: absolute;
  top: 0;
  left: 20px;
  width: calc(100% - 40px);
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  @media(max-width: 767px) {
    left: 0;
    width: 100%;
    img {
      height: 200%;
    }
  }
`

const TestimonialMain = styled.div`
  max-width: 970px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 70px 80px;
  p {
    font-family: "Work Sans";
    font-size: 36px;
    line-height: 42px;
    font-weight: 700;
    color: #1f1e1e;
    margin-bottom: 0;
    text-align: center;
    position: relative;
    z-index: 10;
  }
  img.start-quote {
    position: absolute;
    top: -180px;
    left: -10px;
  }
  img.end-quote {
    position: absolute;
    bottom: -270px;
    right: 0px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 20px;
    left: 20px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 1;
  }
  &:after {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    width: 100%;
    height: 100%;
    border: 10px solid #db4140;
    z-index: 1;
  }
  @media(max-width:1000px) {
    max-width: 570px;
    p {
      font-size: 24px;
      line-height: 1.4;
    }
  }
  @media(max-width: 767px) {
    padding: 25px 50px;
    p {
      font-size: 16px;
    }
    img.start-quote {
      max-width: 50px;
      height: auto;
      top: -80px;
      left: calc(50% - 25px);
    }
    img.end-quote {
      max-width: 50px;
      height: auto;
      bottom: -115px;
      right: calc(50% - 25px);
    }
    &:before {
      top: 10px;
      left: 20px;
      width: calc(100% - 30px);
      height: calc(100% - 5px);
    }
    &:after {
      top: 0px;
      left: 10px;
      width: calc(100% - 25px);
      border: 5px solid #db4140;
    }
  }
`

const TestimonialMeta = styled.div`
  max-width: 970px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-top: 100px;
  padding-bottom: 60px;
  h4 {
    font-family: "Work Sans";
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #1e1f1f;
    margin-top: 0;
    margin-bottom: 10px;
  }
  p {
    font-family: "Work Sans";
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    color: #696969;
    margin-top: 0;
    margin-bottom: 0;
  }
  @media(max-width:1000px) {
    max-width: 570px;
  }
  @media(max-width: 767px) {
    padding-top: 80px;
    padding-bottom: 20px;
    text-align: center;
    h4 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
  }
`

export default ServicesPage

export const pageQuery = graphql`
    query {
        servicePost: allWpOurService(sort: {fields: date, order: DESC}) {
            edges {
                node {
                    title
                    slug
                    ServiceDescription {
                        serviceDescriptionContent
                    }
                }
            }
        }
        pageContent: allWpCustomPage(filter: {databaseId: {eq: 1236}}) {
            edges {
              node {
                SEOmeta {
                    metaTitle
                    metaDescription
                    metaKeywords
                    ogTitle
                    ogDescription
                    ogImage {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 1320, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                    twitterTitle
                    twitterDescription
                    twitterImage {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 1320, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                ServicesPageContent {
                  firstParagraph
                  servicesPageTestimonial {
                    spTestimonialAuthor
                    spTestimonialAuthorInfo
                    spTestimonialContent
                  }
                }
              }
            }
          }
    }
`